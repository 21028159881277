'use client';

import { useEffect } from 'react';
import { useGtmContext } from '@vaimo/google-tag-manager/src/context/gtm';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useCustomer } from './useCustomer';
import { PAGE_TYPES } from '@vaimo/google-tag-manager/src/constants/pageTypes';
import { getBrowserData, getRegion } from '../helpers/utils';

const NEW_DESIGN = 'new design';
const ORIGINAL = 'original';

const HEADLESS = 'headless';
const NON_HEADLESS = 'non-headless';

export const usePageType = ({
    categoryPath = [],
    isComparePathname = true,
    isHeadless = true,
    isSignedIn,
    pageType,
    pathname = '',
    title = '',
}) => {
    const [
        { defaultTitle, isABTestsEnabled, isEnabled, referrerPath, storeCode, storeName, websiteCode },
        {
            actions: { setReferrerPath },
        },
    ] = useGtmContext();

    const isPurchaseStep = pageType === PAGE_TYPES.CHECKOUT_SUCCESS;

    const { guestUserData, loginUserData } = useCustomer({ isEnabled, isPurchaseStep, isSignedIn });

    const path = categoryPath?.map(({ text }) => text).join(' | '); // needs only for Category page

    const condition = !isEnabled || !pageType || (!guestUserData && !isSignedIn) || (!loginUserData && isSignedIn);

    useEffect(() => {
        if (condition || (isComparePathname && referrerPath === pathname)) {
            return;
        }

        const collectedData = {
            browser: getBrowserData(),
            customer: isSignedIn ? loginUserData : guestUserData,
            event: 'page_info',
            page: {
                a_b_test: isABTestsEnabled ? NEW_DESIGN : ORIGINAL,
                page_experience: isHeadless ? HEADLESS : NON_HEADLESS,
                page_location: window.location.href,
                page_path: pathname,
                page_referrer: referrerPath || document.referrer, // will change to internal page on next page
                page_start_ref: document.referrer, // will remain unchanged until next EXTERNAL visit
                page_title: title || defaultTitle,
                page_type: pageType,
            },
            store: {
                country: websiteCode.toUpperCase(),
                language: storeName.toUpperCase(),
                region: getRegion(websiteCode),
                store_code: storeCode,
            },
        };

        actionsTagManager.pageInfo({ ...collectedData });

        if (path) {
            actionsTagManager.category({ path });
        }

        setReferrerPath(pathname);
    }, [isEnabled, pageType, loginUserData, guestUserData, isSignedIn]);

    return condition;
};
