import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { cookieManager } from '@vaimo-int/one-trust';
import { useCartContext } from '@/lib/context';
import { getCustomerData, getFacebookFullDataMutation } from '@vaimo/google-tag-manager/src/talons/tagManager.gql';
import { arrayToObject } from '../helpers/utils';
import { usePathname } from 'next/navigation';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';

const ACCOUNT_TYPE_GENERAL = 'General';
const ACCOUNT_TYPE_GUEST = 'NOT LOGGED IN';
const ACCOUNT_TYPE_PRO = 'PRO Customers';
const COOKIE_GTM_USER_ID = 'vaimo-gtm-user-id';

const deepEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const useCustomer = ({ isEnabled, isPurchaseStep, isSignedIn }) => {
    const [guestUserData, setGuestUserData] = useState(null);
    const [loginUserData, setLoginUserData] = useState(null);
    const prevUserDataRef = useRef(null);
    const pathname = usePathname();
    const [{ cartId }] = useCartContext();

    const { data, error, loading } = useQuery(getCustomerData, {
        fetchPolicy: 'cache-and-network',
        skip: !isSignedIn,
    });

    const [runMutation, { data: userData }] = useMutation(getFacebookFullDataMutation, {
        fetchPolicy: 'no-cache',
    });

    const input = useMemo(() => (isPurchaseStep ? 'purchase' : cartId || null), [isPurchaseStep, cartId]);

    useEffect(() => {
        if (!isEnabled || !input) {
            return;
        }

        runMutation({
            variables: { input },
        });
    }, [pathname, input]);

    useMemo(() => {
        if (!isEnabled) {
            return;
        }

        const facebookPixelUserData = userData?.facebookPixelFullUserData;
        const fb_user_data = facebookPixelUserData?.length ? arrayToObject(facebookPixelUserData) : null;

        if (fb_user_data) {
            if (!deepEqual(fb_user_data, prevUserDataRef.current)) {
                actionsTagManager.faceBookUserData({ fb_user_data });
                prevUserDataRef.current = fb_user_data;
            }
        }
    }, [userData?.facebookPixelFullUserData]);

    useEffect(() => {
        if (!isSignedIn) {
            cookieManager.setCookie({
                groupId: cookieManager.PrivacyGroupEnum.TARGETING,
                name: COOKIE_GTM_USER_ID,
                timeToLiveInMinutes: 0,
                value: '',
            });
            setGuestUserData({ account_type: ACCOUNT_TYPE_GUEST });
        }
    }, [isSignedIn]);

    useEffect(() => {
        if (!isEnabled || !isSignedIn || loading || !data || !data?.customer || error) {
            return;
        }

        // Set Vaimo GTM User ID cookie
        cookieManager.setCookie({
            groupId: cookieManager.PrivacyGroupEnum.TARGETING,
            name: COOKIE_GTM_USER_ID,
            value: data.customer?.customer_id || '',
        });

        const {
            customer: { pro_account },
        } = data;
        const { discount: proDiscount, discount_applicable: isActivePro } = pro_account || {};
        const facebookPixelUserData = userData?.facebookPixelFullUserData;
        const fb_user_data = facebookPixelUserData?.length ? arrayToObject(facebookPixelUserData) : null;

        const proAccountData = isActivePro
            ? {
                  account_type: ACCOUNT_TYPE_PRO,
                  pro_account_discount: proDiscount,
                  pro_account_type: data.customer.pro_account?.classification?.title ?? false,
              }
            : {
                  account_type: ACCOUNT_TYPE_GENERAL,
                  pro_account_type: 'No Classification',
              };

        setLoginUserData({
            ...proAccountData,
            crm_id: data.customer?.customer_id || '', // Vaimo Cookie Until Possibly Replaced in Future
            fb_user_data,
            user_id: data.customer?.customer_id || '', // Vaimo Cookie
        });
    }, [isEnabled, isSignedIn, data?.customer]);

    return {
        guestUserData,
        loginUserData,
    };
};
